export default {
    bankAccountTypes: {STANDARD: "Standart", VIP: "VIP"},
    bankAccountShowTypes: {
        ALL: "Tüm sitelere göster",
        ANY: "Hiç bir siteye gösterme",
        PRIVATE: "Özel sitelere göster",
    },
    bankTypes: {
        PAPARA: "Papara",
        BANK_TRANSFER: "Havale",
        CARD: "Kredi Kartı",
        PAYFIX: "Payfix",
        PAPARA_TO_IBAN: "Papara2Iban",
        PAYCO: "PAYCO"
    },
    depositTypes: {
        PAPARA: "Papara",
        BANK_TRANSFER: "Havale",
        PAYFIX: "Payfix",
        ISYERIMPOS: 'İşyerim POS',
        PAPARA_TO_IBAN: "Papara2Iban",
        PAYTURCA: "PAYTURCA",
        PAYMAGO: "PAYMAGO",
        PAYCO: "PAYCO"
    },
    transactionTypes: {
        SUCCESSFUL: "Onaylandı",
        PENDING: "Beklemede",
        UNSUCCESSFUL: "Başarısız",
        WAITING: "Kontrol B.",
    },
    blacklistTypes: {
        username: "Kullanıcı Adı",
        userId: "Kullanıcı ID",
        name: "Ad Soyad",
        iban: "İban No",
        ccno: "Kredi Kartı No",
    },
    userTypes: {PANEL: "Panel Kullanıcısı", SITE: "API Kullanıcısı"},
    bankAccountStatuses: {
        ENABLED: "Aktif",
        DISABLED: "Pasif",
        RESERVED: "Yedek",
        BLOCKED: "Bloke",
    },
    maldoWithdrawStatuses: {
        TAKED: "Take edildi",
        CONFIRMED: "Confirm edildi",
        DECLINED: "Decline edildi",
        CONFIRM_WAITING: "Confirm onayı bekleniyor",
        DECLINE_WAITING: "Decline onayı bekleniyor",
        KICKED: 'Çekim maldo tarafından geri alındı'
    },
}

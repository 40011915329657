<template>
	<div id="app">
		<loader />
		<router-view />
	</div>
</template>

<script>
	import Loader from "@/components/Loader"
	export default {
		name: "App",
		components: {
			Loader,
		},
		mounted() {
			this.$store.dispatch("setBasic", { key: "loading", value: [] })
			if (this.theme === "1")
				document.querySelector("body").style.paddingLeft = "0"
		}
	}
</script>
<style>
	.section {
		padding: 1rem !important;
	}
</style>

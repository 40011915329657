<template>
  <b-loading v-model="loading" :is-full-page="true"/>
</template>
<script>
export default {
  name: 'Loader',
  computed: {
    loading(){
      return this.$store.state.loading && this.$store.state.loading.length > 0
    }
  }
}
</script>

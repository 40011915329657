<template>
  <div>

    <header v-if="page.title" class="card-header">

      <table-filter :form="filter" :filter-buttons="page.actions" v-if="filter && !filterHide"
        :change-reload="changeReload" :reload="loadAsyncData" />
    </header>
    <finance-table-card :style="{ width: theme === '1' ? '100%' : null, height: theme === '1' ? 'max-content' : null, overflow: 'auto' }"
      class="has-table has-mobile-sort-spaced" :title="page.title" :icon="page.icon" :actions="page.actions">

      <b-table :striped="true" :narrowed="true" :hoverable="true" :mobile-cards="true"
        :data="data && data.length > 0 ? data : []" :loading="loading" paginated backend-pagination
        :per-page="pagination.paginate" @page-change="onPageChange" aria-next-label="Next page"
        aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page" backend-sorting
        :default-sort-direction="pagination.defaultSortOrder" :default-sort="[pagination.sortField, pagination.sortOrder]"
        @sort="onSort" :sticky-header="true" :detailed="settings.details" :height="getHeight" :total="meta.total"
        id="finance-table-container">
        <b-table-column cell-class="is-actions-cell" v-slot="props" :field="column.field" :label="column.label"
          :sortable="column.sortable" v-for="(column, key) in settings.columns" :key="key" :numeric="column.numeric"
          :width="column.width" :style="{ textAlign: column.textAlign ? 'center' : 'left' }">
          <template v-if="column.field === 'actions' && !column.renderComponent">
            <div class="table-actions" style="white-space: nowrap">
              <template v-for="(action, key) in column.actions" v-if="action.perm ? hasPermission(action.perm) : true && (!action.condition || (action.condition && props.row[action.condition.field] === action.condition.value))">
                <b-tooltip :label="action.title" :key="key" type="is-dark" position="is-top" v-if="action.icon">
                  <button class="button is-small mr-3" :class="'is-' + action.type" @click="action.action(action.column === 'row' ? { ...props.row, service: settings.service }
                    : props.row[column.column])">
                    <b-icon :icon="action.icon" :size="action.size || 'is-small'" :type="action.class"></b-icon>
                  </button>
                </b-tooltip>
                <b-button class="mr-3" size="is-small" :type="'is-' + action.type" v-else-if="!action.condition || (action.condition && props.row[action.condition.field] === action.condition.value)"
                  @click="action.action(action.column === 'row' ? { ...props.row, service: settings.service } : props.row[column.column])">
                  {{ action.title }}
                </b-button>
              </template>
            </div>
          </template>
          <template v-if="!column.viewMethod && column.field !== 'actions' && !column.renderComponent">
            <div class="th-wrap" :style="{ ...column.style }" v-if="column.routerMethod" :class="[column.class]">
              <router-link v-if="columnParse(props.row, column.routeId)"
                :to="{ name: column.routeName, params: { id: columnParse(props.row, column.routeId) } }">{{
                  columnParse(props.row, column.field)
                }}
              </router-link>
            </div>
            <span v-else :style="{ ...column.style }" :class="[column.class]">{{ columnParse(props.row, column.field) || '-'
            }}</span>
          </template>
          <template v-if="column.viewMethod && column.renderHtml && !column.renderComponent">
            <div class="th-wrap" :style="{ ...column.style }" :class="['is-block', 'is-full', column.class]"
              v-html="column.viewMethod(column.column === 'row' ? props.row : props.row[column.field])" />
          </template>
          <template v-if="column.viewMethod && !column.renderHtml && !column.renderComponent" :class="[column.class]">
            <div class="th-wrap" :style="{ ...column.style }">{{ props.row[column.field] ?
              column.viewMethod(props.row[column.field]) : '-' }}</div>
          </template>
          <template v-if="column.renderComponent" :class="[column.class]">
            <component :is="column.renderComponent" :props="props.row"></component>
          </template>
        </b-table-column>
        <template slot="detail" slot-scope="props" v-if="settings.details">
          <b-table :data="props.row[settings.detailItems]">
            <b-table-column v-slot="props" :field="column.field" :label="column.label"
              v-for="(column, key) in settings.detailColumns" :key="key">
              <template v-if="column.field === 'actions'">
                <div class="buttons table-actions">
                  <b-tooltip :label="action.title" v-for="(action, key) in column.actions" :key="key" type="is-dark"
                    v-if="action.perm ? hasPermission(action.perm) : true">
                    <button class="button is-small mr-3"
                      @click="action.action(action.column === 'row' ? { ...props.row, service: settings.service } : props.row[column.column])">
                      <b-icon :icon="action.icon" :size="action.size" :type="action.class"></b-icon>
                    </button>
                  </b-tooltip>
                </div>
              </template>
              <template v-else-if="column.field !== 'actions' && column.viewMethod && column.renderHtml">
                <span class="is-block is-full" :class="column.class" :style="{ textAlign: column.textAlign }"
                  v-html="column.viewMethod(props.row[column.field])" />
              </template>
              <template v-else-if="column.field !== 'actions' && column.viewMethod && !column.renderHtml">
                {{
                  props.row[column.field] ? column.viewMethod(column.all ? props.row : props.row[column.field]) : '-'
                }}
              </template>
              <span v-else>{{ columnParse(props.row, column.field) || '-' }}</span>
            </b-table-column>
          </b-table>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="frown-open" size="is-large"></b-icon>
              </p>
              <p>Kayıt bulunamadı</p>
            </div>
          </section>
        </template>
        <template slot="bottom-left">
          <b-select v-model="pagination.paginate" @change.native="loadAsyncData" size="is-small" expanded>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
          </b-select>
          <span class="ml-4 is-italic">{{ meta.total }} kayıttan {{ meta.from }} ila {{ meta.to }} arasındaki kayıtlar
            gösteriliyor.</span>
        </template>
      </b-table>
    </finance-table-card>
  </div>
</template>

<script>
import FinanceTableCard from '@/components/FinanceTableCard'

export default {
  name: 'FinanceTable',
  components: {
    FinanceTableCard
  },
  props: {
    filter: null,
    changeReload: {
      type: Boolean,
      default: false
    },
    filterHide: {
      type: Boolean,
      default: false
    },
    filterButtons: {
      type: Boolean,
      default: true
    },
    page: {
      type: Object,
      default: () => {
        return {
          title: null,
          description: null,
          actions: []
        }
      }
    },
    settings: {
      type: Object,
      default: () => {
        return {
          columns: null,
          service: null,
          method: null,
          details: false,
          detailItems: 'items',
          heightCalc: true
        }
      }
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          paginate: 50,
          page: 1,
          sortField: 'id',
          sortOrder: 'desc',
          defaultSortOrder: 'desc'
        }
      }
    }
  },
  data() {
    return {
      getHeight: 0,
      data: [],
      meta: {},
      loading: false,
      isSwitchedCustom: false
    }
  },
  methods: {
    loadAsyncData() {
      this.loading = true
      let filterParams = {}
      if (this.filter && this.filter.length > 0) {
        this.filter.map(item => {
          if ((item.value !== '' && item.value !== null) || item.hidden === true) {
            if (item.type === 'datepicker') {
              if (typeof item.value === 'object' && item.value.length > 0) {
                if (item.value[0] && item.value[1]) {
                  const startDate = this.getIstanbulDate(item.value[0])
                  const endDate = this.getIstanbulDate(item.value[1], false)
                  filterParams[item.field] = [startDate, endDate]
                }
              } else {
                filterParams[item.field] = item.value
              }
            } else if (item.type === 'switch') {
              filterParams[item.field] = item.value === true ? 1 : 0
            } else {
              filterParams[item.field] = item.value
            }
          }
        })
      }
      const defaultParams = {
        paginate: this.pagination.paginate,
        page: this.pagination.page,
        sort: this.pagination.sortField,
        sortType: this.pagination.sortOrder,
        ...filterParams
      }
      return this.settings.service[this.settings.method](defaultParams)
        .then(({ data }) => {
          this.data = data.docs
          this.meta = {
            total: data.total,
            pages: data.pages,
            from: ((this.pagination.paginate * this.pagination.page) - this.pagination.paginate) + 1,
            to: data.total > this.pagination.paginate * this.pagination.page ? this.pagination.paginate * this.pagination.page : data.total
          }
          return true
        })
        .catch(error => {
          this.data = []
          this.meta = {}
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.loadAsyncData()
    },
    onSort(field, order) {
      this.pagination.sortField = field
      this.pagination.sortOrder = order
      this.loadAsyncData()
    },
    columnParse(data, name) {
      if (name.indexOf('.') > -1) {
        return name.split('.').reduce((o, i) => (o ? o[i] : ''), data)
      }
      return data[name]
    }
  },
  mounted() {
    this.getHeight = '100%'
    if (this.settings.init === false) return
    this.loadAsyncData()
  }
}
</script>


<style lang="scss">
.th-wrap {
  padding: .5rem 0;
}

tr>td,
tr>th {
  vertical-align: middle !important;
  white-space: nowrap;
  font-size: 13px
}

tr>th {
  font-size: 15px;
}
</style>

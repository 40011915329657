import axios from 'axios'
import store from '@/store'
import router from '@/router'

const ax = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	headers: {
		Authorization: store.state.user.accessToken ? 'Bearer ' + store.state.user.accessToken : null,
		Accept: 'application/json',
		ContentType: 'application/json'
	}
})
ax.interceptors.request.use(
	config => {
		store.dispatch('setLoading', true)
		return config
	},
	error => {
		store.dispatch('setLoading', false)
		return Promise.reject(error)
	}
)
ax.interceptors.response.use(
	response => {
		if (store.state.user.accessToken)
			ax.defaults.headers.common['Authorization'] = `${store.state.user.tokenType} ${store.state.user.accessToken}`

		store.dispatch('setLoading', false)
		return response
	},
	error => {
		if (error.response) {
			if (error.response.status === 401) {
				store.dispatch('setLogout')
				router.push({ name: 'login' })
			}
			if (error.response.status === 403) {
				router.push({ name: 'pageNoAccess' })
			}
			if(error.response.status === 500 && error.response.data && error.response.data.ban){
				router.push({ name: 'logout' })
			}
			if (error.response && error.response.data) {
				store.dispatch('setLoading', false)
				return Promise.reject(error.response.data)
			}
		}
		return Promise.reject(error)
	}
)

export const http = ax

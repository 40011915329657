<template>
  <aside v-show="$store.state.isAsideVisible" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true" class="is-flex is-flex-grow-1 is-align-items-center">
      <!--<span slot="label"> {{siteName}} </span>-->
      <span slot="label" class="is-flex"> <img width="150" :src="`/logos/${site}/logo.svg`" /></span>
    </aside-tools>
    <div class="menu is-menu-main" style="height: calc(100% - 56px); overflow: auto">
      <template v-for="(menuGroup, index) in menu">
        <p v-if="typeof menuGroup === 'string'" :key="index" class="menu-label">
          {{ menuGroup }}
        </p>
        <aside-menu-list
          v-else
          :menu="menuGroup"
          @menu-click="menuClick"
        />
      </template>
    </div>
  </aside>
</template>

<script>
import AsideTools from '@/components/AsideTools'
import AsideMenuList from '@/components/AsideMenuList'

export default {
  name: 'AsideMenu',
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    menuClick (item) {
      //
    }
  }
}
</script>
<style>
.aside.aside.is-expanded .menu-list .icon { width: 2rem }
</style>

<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top" :class="{ 'is-dark': theme === '1' }"
    :style="{ 'paddingLeft': theme === '1' ? 0 : null }">
    <div class="navbar-brand">
      <a class="navbar-item" @click.prevent="menuToggleMobile" v-if="theme === '0'">
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <b-navbar-item tag="router-link" :to="{ path: '/' }" v-if="theme === '1'">
        <img :src="`/logos/${site}/logo.svg`" />
      </b-navbar-item>
      <div class="card-header-title has-divider" v-if="theme === '0'">{{ $t($route.meta.title) }}</div>
    </div>
    <div class="navbar-brand is-right mr-3">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuNavBarActive }">
      <div class="navbar-start" v-if="theme === '1'">
        <div class="navbar-item has-divider navbar-item-rbt">
          <router-link :to="{ name: 'home' }" class="navbar-item has-text-white" exact-active-class="is-active">
            Anasayfa
          </router-link>
        </div>
        <div class="navbar-item has-divider navbar-item-rbt" v-if="hasPermission('Menu.Deposit')">
          <router-link :to="{ name: 'deposit' }" class="navbar-item has-text-white" exact-active-class="is-active">
            Yatırımlar
          </router-link>
        </div>
        <div class="navbar-item has-divider navbar-item-rbt" v-if="hasPermission('Menu.Withdraw')">
          <router-link :to="{ name: 'withdraw' }" class="navbar-item has-text-white" exact-active-class="is-active">
            Çekimler
          </router-link>
        </div>
        <div class="navbar-item has-divider navbar-item-rbt" v-if="hasPermission('Menu.BankAccount')">
          <router-link :to="{ name: 'bankAccount' }" class="navbar-item has-text-white" exact-active-class="is-active">
            Hesaplar
          </router-link>
        </div>
        <nav-bar-menu class="has-divider has-user-avatar" v-if="hasPermission('Menu.ManualBankTransfer')">
          <div class="is-user-name">
            <span>Havale</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/bank-transfer-deposit" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.BankTransferDeposit')">
              <span>Yatırımlar</span>
            </router-link>
            <router-link to="/bank-transfer-withdraw" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.BankTransferWithdraw')">
              <span>Çekimler</span>
            </router-link>
            <router-link to="/bank-transfer-accounts" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.BankTransferAccounts')">
              <span>Hesaplar</span>
            </router-link>
          </div>
        </nav-bar-menu>
        <nav-bar-menu class="has-divider has-user-avatar" v-if="hasPermission('Menu.Report')">
          <div class="is-user-name">
            <span>Raporlar</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/report/cage" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.CageReport')">
              <span>KASA</span>
            </router-link>
            <router-link to="/report/yearly-deposit" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.YearlyDepositReport')">
              <span>Yıllık Yatırım</span>
            </router-link>
            <router-link to="/report/yearly-withdraw" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.YearlyWithdrawReport')">
              <span>Yıllık Çekim</span>
            </router-link>
            <router-link to="/report/investor" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.PlayerReport')">
              <span>Yatırımcı</span>
            </router-link>
            <router-link to="/report/daily-account" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.DailyAccountReport')">
              <span>Hesap</span>
            </router-link>
            <router-link to="/report/failed-transaction-report" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.FailedTransactionReport')">
              <span>Yatırım</span>
            </router-link>
            <router-link to="/report/bank-transfer-group-report" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.BankTransferGroupReport')">
              <span>Havale & Grup</span>
            </router-link>
          </div>
        </nav-bar-menu>
        <nav-bar-menu class="has-divider has-user-avatar" v-if="hasPermission('Menu.DefinitionManagement')">
          <div class="is-user-name">
            <span>Tanımlamalar</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/definition/payment-method" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.PaymentMethod')">
              <span>Ödeme Yöntemleri</span>
            </router-link>
            <router-link to="/definition/banklist" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.Bank')">
              <span>Bankalar</span>
            </router-link>
            <router-link to="/definition/site" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.Site')">
              <span>Siteler</span>
            </router-link>
            <router-link to="/definition/blacklist" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.Blacklist')">
              <span>Karaliste</span>
            </router-link>
            <router-link to="/investors" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.Investors')">
              <span>Yatırımcılar</span>
            </router-link>
            <router-link to="/definition/setting" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.Setting')">
              <span>Havale Grup Ayarları</span>
            </router-link>
          </div>
        </nav-bar-menu>
        <nav-bar-menu class="has-divider has-user-avatar" v-if="hasPermission('Menu.UserManagement')">
          <div class="is-user-name">
            <span>Kullanıcı Yönetimi</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/definition/online-users" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.OnlineUsers')">
              <span>Online Kullanıcılar</span>
            </router-link>
            <router-link to="/definition/user" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.User')">
              <span>Kullanıcılar</span>
            </router-link>
            <router-link to="/definition/role" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.Role')">
              <span>Roller</span>
            </router-link>
            <router-link to="/definition/permission" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.Permission')">
              <span>Yetkiler</span>
            </router-link>
            <router-link to="/api-creator" class="navbar-item" exact-active-class="is-active"
              v-if="hasPermission('Menu.ApiCreator')">
              <span>API Creator</span>
            </router-link>
          </div>
        </nav-bar-menu>
      </div>
      <div class="navbar-end">
        <div class="navbar-item" style="cursor:pointer" title="Oturumu Yenile" @click="refreshToken"
          v-if="!$store.state.user.siteId && logoutSecond < 301">
          <b-icon icon="sync" custom-size="default" />
          {{ logoutSecond }}s
        </div>
        <div class="navbar-item" style="cursor:pointer; font-weight: bold" @click="setLang">{{ $store.state.lang.toUpperCase() }}</div>
        <div class="navbar-item" style="cursor:pointer" @click="toggleSocket">
          <b-icon :type="$store.state.setting.socket ? (theme === '0' ? 'is-dark' : 'is-white') : 'is-success'"
            :icon="$store.state.setting.socket ? 'stop' : 'play'" custom-size="default" />
          <span>{{ $store.state.setting.socket ? this.$t('def.liveStop') :  this.$t('def.liveStart') }}</span>
        </div>
        <a class="navbar-item is-desktop-icon-only" v-if="hasPermission('ActivityLog.List')" :title="$t('def.processLogs')"
          @click="$router.push({ name: 'activityLogs' })">
          <b-icon :icon="'hiking'" custom-size="default" />
          <span>{{$t('def.processLogs')}}</span>
        </a>
        <a class="navbar-item is-desktop-icon-only" :title="$t('def.settings')" @click="$router.push({ name: 'profile' })">
          <b-icon icon="account" custom-size="default" />
          <span>{{ $t('def.settings') }}</span>
        </a>
        <a class="navbar-item is-desktop-icon-only" :title="$t('def.logout')" @click="logout">
          <b-icon icon="logout" custom-size="default" />
          <span>{{$t('def.logout')}}</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import HeroBar from '@/components/HeroBar'
import UserService from "@/services/user.service";
import { http } from "@/utils/axios";

const gmt = (new Date().getTimezoneOffset() * -1) / 60
export default {
  name: 'NavBar',
  components: {
    NavBarMenu,
    HeroBar
  },
  data() {
    return {
      isMenuNavBarActive: false,
      gmt: gmt > 0 ? 'GMT+' + gmt : (gmt === 0 ? '0' : 'GMT-' + gmt),
      logoutInterval: null,
      logoutSecond: 5
    }
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    ...mapState(['isNavBarVisible', 'isAsideMobileExpanded'])
  },
  async mounted() {
    this.$router.afterEach(() => this.isMenuNavBarActive = false)
    if (!this.$store.state.user.siteId) {
      this.logoutInterval = setInterval(() => {
        this.logoutSecond = this.$store.state.user.logout - Math.floor(Date.now() / 1000)
        if (this.logoutSecond <= 0) this.$router.push({ name: 'logout' })
      }, 1000)
    }
    document.querySelectorAll('a.navbar-item:hover').forEach(item => item.style.backgroundColor = 'red')
  },
  methods: {
    setLang(){
      const lang = this.$store.state.lang === 'tr' ? 'en' : 'tr'
      console.log(lang, this.$i18n)
			this.$i18n.locale = lang
      this.$store.dispatch('setLang', lang)
    },
    menuToggleMobile() {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive
    },
    logout() {
      this.$router.push({ name: 'logout' })
    },
    refreshToken() {
      UserService.refreshToken().then(async res => {
        const user = this.$store.state.user
        user.accessToken = res.data.token
        user.login = res.data.login
        user.logout = res.data.logout
        await this.$store.dispatch('setBasic', { key: 'user', value: { ...user } })
        http.defaults.headers['Authorization'] = `${user.tokenType} ${user.accessToken}`
        this.$buefy.toast.open({ message: 'Oturum güncellendi', type: 'is-success' })
      })
        .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    }
  },
  beforeDestroy() {
    clearInterval(this.logoutInterval)
  }
}
</script>
<style>
.navbar-item-rbt:hover {
  background-color: #605f5f !important;
}
.navbar-item-rbt > a.navbar-item.is-active {
  background-color: transparent!important;
}
.navbar-item-rbt>a:hover {
  background-color: unset;
}
</style>

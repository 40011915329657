<template>
  <div class="card">
    <!--
    <header v-if="title" class="card-header">
      <p class="card-header-title">
        <b-icon v-if="icon" :icon="icon" custom-size="default"/>
        {{ title }}
      </p>
      <div class="is-flex is-align-items-center is-flex-wrap-wrap" v-if="actions && actions.length > 0" >
        <b-button :key="key" v-for="(action, key) in actions" v-if="!action.perm || hasPermission(action.perm)" :type="'is-'+action.type" size="is-small" class="card-header-icon mr-3" :icon-left="action.icon"
                  @click.prevent="action.action()">{{action.title}}</b-button>
      </div>
    </header>
  -->
    <div class="card-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceTableCardComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    actions: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

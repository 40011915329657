import {http} from "@/utils/axios";

const SERVICE = 'user/';

const METHODS = {
    PASSWORD_CHANGE: '/password-change',
    RESET_2FA: '/reset-2fa',
    PROVIDER_CHECK: 'provider-check',
    REFRESH_TOKEN: 'refresh-token'
};

export default {
    list(params = {}) {
        return http.get(SERVICE, {params});
    },
    remove(id) {
        return http.delete(SERVICE + id);
    },
    create(params = {}) {
        return http.post(SERVICE, params);
    },
    update(params = {}) {
        return http.put(SERVICE + params.uuid, params);
    },
    edit(id) {
        return http.get(SERVICE + id);
    },
    reset2fa(params = {}) {
        return http.put(SERVICE + params.id + METHODS.RESET_2FA, params);
    },
    passwordChange(params = {}) {
        return http.put(SERVICE + params.uuid + METHODS.PASSWORD_CHANGE, params);
    },
    providerCheck(params = {}) {
        return http.post(METHODS.PROVIDER_CHECK, params)
    },
    refreshToken(params = {}) {
        return http.get(METHODS.REFRESH_TOKEN, params);
    }
};

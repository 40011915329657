import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [createPersistedState()],
	state: {
		user: {},
		isLoggedIn: false,
		socket: false,
		/* NavBar */
		isNavBarVisible: true,

		/* FooterBar */
		isFooterBarVisible: true,

		/* Aside */
		isAsideVisible: true,
		isAsideMobileExpanded: false,
		loading: [],
		setting: {
			socket: true,
		},
		lang: 'tr'
	},
	mutations: {
		/* A fit-them-all commit */
		basic(state, payload) {
			state[payload.key] = payload.value;
		},

		/* Aside Mobile */
		asideMobileStateToggle(state, payload = null) {
			const htmlClassName = "has-aside-mobile-expanded";

			let isShow;

			if (payload !== null) {
				isShow = payload;
			} else {
				isShow = !state.isAsideMobileExpanded;
			}

			if (isShow) {
				document.documentElement.classList.add(htmlClassName);
			} else {
				document.documentElement.classList.remove(htmlClassName);
			}

			state.isAsideMobileExpanded = isShow;
		},

		loading(state, payload) {
			if (payload) state.loading.push(true);
			else state.loading.splice(-1);
		},
		setting(state, payload) {
			state.setting = payload;
		},
		lang(state, payload) {
			state.lang = payload;
		},
	},
	actions: {
		setLang(context, payload) {
			context.commit("lang", payload);
		},
		setLoading(context, payload) {
			context.commit("loading", payload);
		},
		setUser(context, payload) {
			context.commit("setUser", payload);
		},
		setSetting(context, payload) {
			context.commit("setting", payload);
		},
		setBasic(context, payload) {
			context.commit("basic", payload);
		},
		setLogout(context, payload) {
			context.commit("basic", { key: "isLoggedIn", value: false });
			context.commit("basic", { key: "user", value: {} });
		},
	},
});

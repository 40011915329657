const { parseISO, addDays, isBefore } = require('date-fns');
import store from '../store'

// Verilen tarihin üzerinden 7 gün geçip geçmediğini kontrol eden fonksiyon
function checkIfSevenDaysPassed(utcDateString) {
    // ISO formatındaki UTC tarihini parse et
    const utcDate = parseISO(utcDateString);

    // Verilen tarihe 10 gün ekle
    const sevenDaysLater = addDays(utcDate, 180);

    // Bugünün tarihini al
    const today = new Date();

    // Bugün, 180 gün eklenmiş tarihten sonra mı kontrol et
    return isBefore(sevenDaysLater, today);
}

export default function auth ({ next, router, to }) {
    const utcDateString = store.state.user.lastUpdateDate
    if (store.state.user.lastUpdateDate === null || checkIfSevenDaysPassed(utcDateString)) {
        return router.push({name: 'profile'})
    }
    return next()
}

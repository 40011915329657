<template>
	<div
		class="table-filter"
		:style="{
			height: theme === '1' ? 'max-content' : null,
			width: theme === '0' ? '100%' : null,
		}">
		<form autocomplete="on" @submit.prevent="loadData">
			<div class="table-filter__wrapper is-flex is-align-items-center">
				<div
					class="table-filter__item"
					:style="{ width: theme === '1' ? '100%' : null }"
					v-for="(val, key) in form"
					v-if="!val.hidden"
					:key="key">
					<b-field
						:key="key"
						:label="val.name"
						class="mb-0"
						v-if="val.type === 'input'">
						<b-input
							expanded
							autocomplete="on"
							:name="val.name"
							:placeholder="val.name"
							v-model="val.value"
							@keypress.enter.native="loadData"
							size="is-small"
							style="max-width: 150px" />
					</b-field>
					<b-field
						:key="key"
						:label="val.name"
						class="mb-0"
						v-if="val.type === 'switch'">
						<b-switch
							v-model="val.value"
							type="is-success"
							size="is-small"></b-switch>
					</b-field>
					<b-field
						:label="val.name"
						v-if="val.type === 'datepicker'"
						:key="key"
						class="mb-0">
						<finance-datepicker
							:lang="datepicker.datePickerLang"
							v-mask="'##.##.####-##.##.####'"
							v-model="val.value"
							type="date"
							range-separator="-"
							range
							clearable
							format="DD.MM.YYYY"
							:shortcuts="datepicker.shortcuts" />
					</b-field>
					<b-field
						:label="val.name"
						v-if="val.type === 'enum'"
						:key="key"
						class="mb-0">
						<b-select
							v-model="val.value"
							size="is-small"
							expanded
							@change.native="loadData">
							<option :value="null">{{ val.name }}</option>
							<option
								v-for="(item, enumKey) in enums[val.enumType]"
								:value="enumKey"
								:key="enumKey">
								{{ item }}
							</option>
						</b-select>
					</b-field>
					<b-field
						:label="val.name"
						v-if="val.type === 'select'"
						:key="key"
						class="mb-0">
						<b-select
							v-model="val.value"
							size="is-small"
							expanded
							@change.native="loadData">
							<option :value="null">{{ val.name }}</option>
							<option
								v-for="item in val.data"
								:value="val.id ? item[val.id] : item.uuid"
								:key="item.uuid">
								{{ item.name }}
							</option>
						</b-select>
					</b-field>
					<b-field v-if="val.type === 'tab'" :key="key" class="mb-0">
						<b-tabs
							type="is-toggle"
							v-model="val.value"
							expanded
							animated
							size="is-small">
							<b-tab-item
								v-for="(listItem, key) in val.list"
								:label="listItem.name"
								:value="listItem.id"
								:key="key" />
						</b-tabs>
					</b-field>
				</div>
				<div
					class="table-filter__buttons is-flex is-align-self-flex-end is-justify-content-end">
					<!-- <b-button class="mr-2" :loading="loading" type="is-warning" icon-left="recycle" size="is-small" @click="formClear">Sıfırla</b-button>-->
					<b-button
						:key="key"
						v-for="(action, key) in filterButtons"
						v-if="!action.perm || hasPermission(action.perm)"
						:type="'is-' + action.type"
						size="is-small"
						class="card-header-icon ml-3"
						:icon-left="action.icon"
						@click.prevent="action.action()"
						>{{ action.title }}</b-button
					>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import _ from "lodash"

	export default {
		name: "TableFilter",
		props: ["form", "reload", "filterButtons", "changeReload"],
		data() {
			return {
				isSwitchedCustom: false,
				loading: false,
				keyPress: null,
			}
		},
		methods: {
			loadData(e) {
				e.preventDefault()
				this.reload()
			},
			formClear() {
				this.form.map((item) => {
					if (!item.hidden) {
						item.val = null
						item.value = null
					}
				})
				this.reload()
			},
			filteredDataArray(list, model) {
				return list.filter((option) => {
					return (
						option.name
							.toString()
							.toLowerCase()
							.indexOf(model ? model.toLowerCase() : "") >= 0
					)
				})
			},
			dateParser(date) {
				const dates = date.split("-")
				dates.map((item, key) => {
					const tmp = item.trim().split(".")
					dates[key] = new Date(tmp[2], tmp[1] - 1, tmp[0])
				})
				return dates
			},
		},
		mounted() {
			this.keyPress = _.debounce(this.loadData, 500)
		},
		watch: {
			form: {
				deep: true,
				handler() {
					if (this.changeReload)
						document.getElementById("filterSubmitBtn").click()
				},
			},
		},
	}
</script>

<style lang="scss">
	.table-filter {
		background-color: white;
		padding: 0.5rem;
		border-radius: 0.5rem;
		margin-bottom: 0.5rem;

		.table-filter__item {
			margin-right: 0.25rem;
		}

		label {
			white-space: nowrap;
		}

		.control.is-expanded {
			width: 100% !important;
			max-width: 100% !important;
		}

		.tab-content {
			padding: 0 !important;
		}

		.mx-input {
			max-width: 190px;
			padding: 0.5rem 1rem 0.5rem 0.5rem !important;
			height: 30px;
		}

		.mx-datepicker-range {
			max-width: 190px;
		}

		.mx-datepicker {
			max-width: 190px;
		}

		.field.is-grouped {
			align-items: end;
		}
	}

	@media only screen and (min-width: 1025px) and (max-width: 1260px) {
	}

	@media only screen and (max-width: 600px) {
		.table-filter__wrapper {
			flex-wrap: wrap;
		}

		.table-filter__item {
			display: flex;
			flex: 1 1 100%;
			margin-bottom: 0.5rem;
		}

		.table-filter__item .field {
			width: 100% !important;
		}
	}

	@media only screen and (min-width: 601px) and (max-width: 1024px) {
		.table-filter__wrapper {
			flex-wrap: wrap;
		}

		.table-filter__item {
			display: flex;
			flex: 1 1 25%;
			margin-bottom: 0.5rem;
			margin-right: 0.25rem;
		}

		.table-filter__item .field {
			width: 100% !important;
		}

		.table-filter__item {
			max-width: 125px !important;
		}
	}
</style>
